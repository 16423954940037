import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; 
import userReducer from './userSlice';
import petReducer from './petSlice'; 

const persistConfigUser = {
  key: 'user',
  storage,
};

const persistConfigPet = {
  key: 'pet',
  storage,
};

const persistedUserReducer = persistReducer(persistConfigUser, userReducer);
const persistedPetReducer = persistReducer(persistConfigPet, petReducer);

const store = configureStore({
  reducer: {
    user: persistedUserReducer,
    pet: persistedPetReducer, 
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

const persistor = persistStore(store);

export { store, persistor };
