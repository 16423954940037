import React, { useState, useEffect } from "react";
import { PopupBar } from "../components/Utilities/PopupBar";
import { useNavigate, Navigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { clearUser, resetLinking } from '../redux/userSlice';
import { Banner } from "../components/Utilities/Snackbar";
import { Grid, IconButton } from "@mui/material";
import axios from "axios";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import PersonRoundedIcon from "@mui/icons-material/PersonRounded";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import Spinner from "../components/Utilities/Spinner";
import EditIcon from "@mui/icons-material/Edit";
import LogoutIcon from "@mui/icons-material/Logout";
import DeleteIcon from '@mui/icons-material/Delete';
import CancelIcon from '@mui/icons-material/Cancel';
import PetsIcon from '@mui/icons-material/Pets';
import { isAuthenticated } from "../components/Utilities/Authentification";
import { red } from "@mui/material/colors";
import { top100Breeds, top100CatBreeds, animalTypes, capitalize } from "../components/Utilities/Utils";
import AvatarUpload from "../components/AvatarUpload";
import { GoogleMapsCoordsGetter } from "../components/GoogleMaps";
import { GoogleMapsViewer } from '../components/GoogleMaps';
import { clearPet, setPet } from "../redux/petSlice";
import ResponsiveDialog from "../components/Dialog/LinkPetDialog";
import DeleteAccountDialog from "../components/Dialog/DeleteAccountDialog";

const MyPet = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const qrCodeIdFromParams = searchParams.get("id");
  const [isEditing, setIsEditing] = useState({
    ownerInfo: false,
    petInfo: false,
    address: false,
  });
  const [isGroupOpen, setIsGroupOpen] = useState({
    ownerInfo: false,
    petInfo: false,
    address: false,
  });
  const [ownerData, setOwnerData] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    cityAddress: "",
    streetAddress: "",
    buildingAddress: "",
    lat: 0,
    lng: 0
  });
  const [originalOwnerData, setOriginalOwnerData] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    cityAddress: "",
    streetAddress: "",
    buildingAddress: "",
    lat: 0,
    lng: 0
  });
  const [petData, setPetData] = useState({
    ownerEmail: "",
    petType: "",
    petName: "",
    petBreed: "",
  });
  const [originalData, setOriginalData] = useState({
    ownerEmail: "",
    petType: "",
    petName: "",
    petBreed: "",
  });
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [isPetInfoValid, setIsPetInfoValid] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [qrCodeId, setQrCodeId] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const { email } = useSelector((state) => state.user.userInfo);
  const { isLinkingTag } = useSelector((state) => state.user);


  const [isAuthDone, setIsAuthDone] = useState(false);
  const [isAuth, setIsAuth] = useState(false);

  useEffect(() => {
    setIsPetInfoValid(animalTypes.includes(capitalize(petData.petType)));
  }, [petData.petType]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const allPetsResponse = await axios.get(`${global.config.serverUrl}/getAllPets/`, {
          headers: {
            'email': email,
            'token': localStorage.getItem("loginToken")
          },
        });

        if (allPetsResponse.data) {
          const qrCodeId = qrCodeIdFromParams && !isLinkingTag ? qrCodeIdFromParams : allPetsResponse.data[0].qrCodeId;
          dispatch(setPet(allPetsResponse.data));
          setQrCodeId(qrCodeId);

          if (qrCodeId) {
            const ownerResponse = await axios.get(`${global.config.serverUrl}/getOwner/`, {
              headers: {
                'email': email,
              },
            });

            if (ownerResponse.data) {
              setOwnerData(ownerResponse.data);
              setOriginalOwnerData(ownerResponse.data);
            } else {
              throw new Error("Failed to load owner data");
            }

            const petResponse = await axios.get(`${global.config.serverUrl}/getPet/`, {
              headers: {
                'id': qrCodeId,
              },
            });

            if (petResponse.data) {
              setPetData(petResponse.data);
              setOriginalData(petResponse.data);
              setIsLoading(false);
            } else {
              throw new Error("Failed to load pet data");
            }
          }
        } else {
          throw new Error("Failed to load all pets data");
        }
      } catch (error) {
        setSnackbarMessage("An error has occurred while loading your data...");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
        setIsLoading(false);
      }
    };

    fetchData();
    setIsLoading(false);
  }, [email, qrCodeId, dispatch]);

  useEffect(() => {
    (async () => {
      setIsAuth(await isAuthenticated(email));
      setIsAuthDone(true);
    })();
  }, [email]);

  if (!isAuthDone) return <Spinner open={true} />;
  if (!isAuth) return <Navigate to={"/"} />;

  const logout = () => {
    dispatch(clearUser());
    dispatch(clearPet());
    dispatch(resetLinking());
    localStorage.removeItem("loginToken");
    navigate("/");
  };

  const managePets = () => {
    navigate("/manage-pets");
  };

  const showDeleteDialog = () => {
    setIsDeleting(true);
  };


  const toggleEdit = (section) => {
    setIsEditing((prev) => ({ ...prev, [section]: !prev[section] }));
    if (isEditing[section]) {
      setIsGroupOpen((prev) => ({ ...prev, [section]: true }));
    } else {
      if (section === "ownerInfo") {
        let { firstName, lastName, phoneNumber } = originalOwnerData;
        setOwnerData({ ...originalOwnerData, firstName, lastName, phoneNumber });
      } else if (section === "petInfo") {
        let { petBreed, petName, petType } = originalData;
        setPetData({ ...petData, petBreed, petName, petType });
      } else if (section === "address") {
        let { cityAddress, buildingAddress, streetAddress, lat, lng } = originalOwnerData;
        setOwnerData({ ...ownerData, cityAddress, buildingAddress, streetAddress, lat, lng });
      }
    }
  };

  const toggleGroupOpen = (section) => {
    setIsGroupOpen((prev) => ({ ...prev, [section]: !prev[section] }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setOwnerData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleInputChangePet = (e) => {
    const { name, value } = e.target;
    setPetData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSave = (section) => {
    setSnackbarMessage(`${section} data saved successfully!`);
    setSnackbarSeverity("success");
    setSnackbarOpen(true);


    (async () => {
      try {
        await axios.post(
          `${global.config.serverUrl}/postOwner/`,
          {
            firstName:
              section === "ownerInfo" ? capitalize(ownerData.firstName) : null,
            lastName:
              section === "ownerInfo" ? capitalize(ownerData.lastName) : null,
            phoneNumber: section === "ownerInfo" ? ownerData.phoneNumber : null,

            cityAddress:
              section === "address" ? capitalize(ownerData.cityAddress) : null,
            streetAddress:
              section === "address" ? capitalize(ownerData.streetAddress) : null,
            buildingAddress:
              section === "address"
                ? capitalize(ownerData.buildingAddress)
                : null,
            lat:
              section === "address"
                ? ownerData.lat
                : null,
            lng:
              section === "address"
                ? ownerData.lng
                : null,
          },
          {
            headers: {
              'email': email,
              'token': localStorage.getItem("loginToken")
            },
          }
        );

        await axios.post(
          `${global.config.serverUrl}/postPet/`,
          {
            petType: section === "petInfo" ? capitalize(petData.petType) : null,
            petBreed:
              section === "petInfo" ? capitalize(petData.petBreed) : null,
            petName: section === "petInfo" ? capitalize(petData.petName) : null,
          },
          {
            headers: {
              'id': qrCodeId,
              'email': email,
              'token': localStorage.getItem("loginToken")
            },
          }
        );
      } catch (error) {
        setSnackbarMessage("Error saving user data. Please try again.");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
        return;
      }
    })();

    if (section === "ownerInfo") {
      let { firstName, lastName, phoneNumber } = ownerData;
      setOriginalOwnerData({ ...originalOwnerData, firstName, lastName, phoneNumber });
    } else if (section === "petInfo") {
      let { petBreed, petName, petType } = petData;
      setOriginalData({ ...originalData, petBreed, petName, petType });
    } else if (section === "address") {
      let { cityAddress, buildingAddress, streetAddress, lat, lng } = ownerData;
      setOriginalOwnerData({ ...originalOwnerData, cityAddress, buildingAddress, streetAddress, lat, lng });
    }

    setIsEditing((prev) => ({ ...prev, [section]: false }));
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const getAnimalBreed = (type) => {
    return type === 'Dog' ? top100Breeds : top100CatBreeds;
  };

  const setBreedValue = () => {
    if (petData.petBreed === "undefined")
      return '';
    return !getAnimalBreed(petData.petType).includes(petData.petBreed) ? '' : petData.petBreed;
  };

  return (
    <div
      style={{
        display: "flex",
        backgroundColor: "#f5f5dc",
        color: "#353634",
        minHeight: "110vh",
      }}
    >
      <Drawer
        id="side-bar"
        variant="permanent"
        sx={{
          width: "60px",
          marginRight: "5px",
          "& .MuiDrawer-paper": {
            backgroundColor: "#B4D479",
            flexDirection: "column-reverse",
          },
        }}
      >
<PopupBar
  buttons={[
    [
      { value: "Manage Pets", onClick: managePets, icon: <PetsIcon /> },
      { value: "Logout", onClick: logout, icon: <LogoutIcon /> },
    ],
    [
      { value: "Delete Account", onClick: showDeleteDialog, icon: <DeleteIcon />, color: red[800] }
    ],
  ]}
  firstName={originalOwnerData.firstName}
  lastName={originalOwnerData.lastName}
/>

      </Drawer>

      <div
        id="main-content"
        style={{
          padding: "20px",
          flex: 1,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginLeft: "-20px",
          marginTop: '50px'
        }}
      >
        <>
        <AvatarUpload qrCodeId={qrCodeId}/>

        <div style={{ width: "100%", maxWidth: "600px", marginTop: "30px" }}>
          <Accordion
            expanded={isEditing.ownerInfo || isGroupOpen.ownerInfo} style={{ marginBottom: "30px" }}>
            <AccordionSummary
              onClick={() => toggleGroupOpen("ownerInfo")}
              expandIcon={
                <>
                  <IconButton onClick={(e) => { e.stopPropagation(); toggleEdit("ownerInfo"); }} sx={{ marginRight: '-20px' }}>
                    {
                      isEditing.ownerInfo
                        ? <CancelIcon sx={{ color: red[800] }} />
                        : <EditIcon />
                    }
                  </IconButton>
                  {
                    isEditing.ownerInfo || isGroupOpen.ownerInfo
                      ? <ExpandLessIcon sx={{ marginLeft: '-30px', marginTop: '9px' }} />
                      : <ExpandMoreIcon sx={{ marginLeft: '-30px', marginTop: '9px' }} />
                  }
                </>
              }
              aria-controls="panel3-content"
              id="panel3-header"
              style={{ backgroundColor: "#B4D479" }}
              sx={{
                "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
                  transform: "none"
                },
                "& .MuiAccordionSummary-expandIconWrapper": {
                  "& :hover": {
                    backgroundColor: "#B4D479"
                  }
                },
              }}
            >
              <Grid container alignItems="center">
                <PersonRoundedIcon sx={{ marginRight: "8px" }} />
                Owner Information
              </Grid>
            </AccordionSummary>
            <AccordionDetails style={{ backgroundColor: "#f5f5dc" }}>
              {isEditing.ownerInfo ? (
                <>
                  <TextField
                    label="First Name"
                    name="firstName"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={ownerData.firstName ?? ''}
                    onChange={handleInputChange}
                  />
                  <TextField
                    label="Last Name"
                    name="lastName"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={ownerData.lastName ?? ''}
                    onChange={handleInputChange}
                  />
                  <TextField
                    label="Phone Number"
                    name="phoneNumber"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={ownerData.phoneNumber ?? ''}
                    onChange={handleInputChange}
                  />
                  <TextField
                    label="Email"
                    name="email"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={email ?? ''}
                    onChange={handleInputChange}
                    disabled={true}
                  />
                  <Button
                    variant="contained"
                    sx={{ backgroundColor: '#476569' }}
                    onClick={() => handleSave("ownerInfo")}
                  >
                    Save
                  </Button>
                </>
              ) : (
                <>
                  <p><strong> First Name:</strong> {originalOwnerData.firstName}</p>
                  <p><strong> Last Name:</strong> {originalOwnerData.lastName}</p>
                  <p><strong> Phone Number:</strong> {originalOwnerData.phoneNumber || '-'}</p>
                  <p><strong> Email:</strong> {email}</p>
                </>
              )}
            </AccordionDetails>
          </Accordion>

          <Accordion
            expanded={isEditing.petInfo || isGroupOpen.petInfo} style={{ marginBottom: "30px" }}>
            <AccordionSummary
              onClick={() => toggleGroupOpen("petInfo")}
              expandIcon={
                <>
                  <IconButton onClick={(e) => { e.stopPropagation(); toggleEdit("petInfo"); }} sx={{ marginRight: '-20px' }}>
                    {
                      isEditing.petInfo
                        ? <CancelIcon sx={{ color: red[500] }} />
                        : <EditIcon />
                    }
                  </IconButton>
                  {
                    isEditing.petInfo || isGroupOpen.petInfo
                      ? <ExpandLessIcon sx={{ marginLeft: '-30px', marginTop: '9px' }} />
                      : <ExpandMoreIcon sx={{ marginLeft: '-30px', marginTop: '9px' }} />
                  }
                </>
              }
              aria-controls="panel1-content"
              id="panel1-header"
              style={{ backgroundColor: "#B4D479" }}
              sx={{
                "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
                  transform: "none"
                },
                "& .MuiAccordionSummary-expandIconWrapper": {
                  "& :hover": {
                    backgroundColor: "#B4D479"
                  }
                },
              }}
            >
              <Grid container alignItems="center">
                <PetsIcon sx={{ marginRight: "8px" }} />
                Pet Information
              </Grid>
            </AccordionSummary>
            <AccordionDetails style={{ backgroundColor: "#f5f5dc" }}>
              {isEditing.petInfo ? (
                <>
                  <TextField
                    label="Pet Type"
                    name="petType"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={petData.petType ?? ''}
                    onChange={handleInputChangePet}
                    error={!isPetInfoValid}
                    helperText={
                      !isPetInfoValid
                        ? "Invalid pet type. Please select a valid type."
                        : ""
                    }
                    select
                    SelectProps={{ native: true }}
                  >
                    {animalTypes.map((type) => (
                      <option key={type} value={type}>
                        {type}
                      </option>
                    ))}
                  </TextField>
                  <TextField
                    label="Pet Name"
                    name="petName"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={petData.petName ?? ''}
                    onChange={handleInputChangePet}
                  />
                  <Autocomplete
                    options={getAnimalBreed(petData.petType)}
                    freeSolo
                    value={setBreedValue()}
                    onChange={(event, newValue) => {
                      setPetData((prevData) => ({
                        ...prevData,
                        petBreed: newValue,
                      }));
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Pet Breed"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                      />
                    )}
                  />
                  <Button
                    variant="contained"
                    sx={{ backgroundColor: '#476569' }}
                    onClick={() => handleSave("petInfo")}
                    disabled={!isPetInfoValid}
                  >
                    Save
                  </Button>
                </>
              ) : (
                <>
                  <p><strong> Type:</strong> {originalData.petType || '-'}</p>
                  <p><strong> Name:</strong> {originalData.petName || '-'}</p>
                  <p><strong> Breed:</strong> {originalData.petBreed || '-'}</p>
                </>
              )}
            </AccordionDetails>
          </Accordion>

          <Accordion
            expanded={isEditing.address || isGroupOpen.address} style={{ marginBottom: "30px" }}>
            <AccordionSummary
              onClick={() => toggleGroupOpen("address")}
              expandIcon={
                <>
                  <IconButton onClick={(e) => { e.stopPropagation(); toggleEdit("address"); }} sx={{ marginRight: '-20px' }}>
                    {
                      isEditing.address
                        ? <CancelIcon sx={{ color: red[800] }} />
                        : <EditIcon />
                    }
                  </IconButton>
                  {
                    isEditing.address || isGroupOpen.address
                      ? <ExpandLessIcon sx={{ marginLeft: '-30px', marginTop: '9px' }} />
                      : <ExpandMoreIcon sx={{ marginLeft: '-30px', marginTop: '9px' }} />
                  }
                </>
              }
              aria-controls="panel2-content"
              id="panel2-header"
              style={{ backgroundColor: "#B4D479" }}
              sx={{
                "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
                  transform: "none"
                },
                "& .MuiAccordionSummary-expandIconWrapper": {
                  "& :hover": {
                    backgroundColor: "#B4D479"
                  }
                },
              }}
            >
              <Grid container alignItems="center">
                <HomeRoundedIcon sx={{ marginRight: "8px" }} />
                Address
              </Grid>
            </AccordionSummary>
            <AccordionDetails style={{ backgroundColor: "#f5f5dc" }}>
              {isEditing.address ? (
                <>
                  <TextField
                    label="City"
                    name="cityAddress"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={ownerData.cityAddress ?? ''}
                    onChange={handleInputChange}
                  />
                  <TextField
                    label="Street"
                    name="streetAddress"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={ownerData.streetAddress ?? ''}
                    onChange={handleInputChange}
                  />
                  <TextField
                    label="Building"
                    name="buildingAddress"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={ownerData.buildingAddress ?? ''}
                    onChange={handleInputChange}
                  />
                  <GoogleMapsCoordsGetter data={ownerData} dataSetter={setOwnerData} />
                  <Button
                    variant="contained"
                    sx={{ backgroundColor: '#476569', mt: 2 }}
                    onClick={() => handleSave("address")}
                  >
                    Save
                  </Button>
                </>
              ) : (
                <>
                  <p><strong>City:</strong> {originalOwnerData.cityAddress || '-'}</p>
                  <p><strong> Street:</strong> {originalOwnerData.streetAddress || '-'}</p>
                  <p><strong> Building:</strong> {originalOwnerData.buildingAddress || '-'}</p>
                  <p style={{marginTop: '5px'}}><strong> Your Home Location:</strong> </p>
                  <GoogleMapsViewer coordinates={{ lat: ownerData.lat, lng: ownerData.lng }} />
                </>
              )}
            </AccordionDetails>
          </Accordion>
        </div>
        </>
        <Banner
          open={snackbarOpen}
          message={snackbarMessage}
          severity={snackbarSeverity}
          handleClose={handleSnackbarClose}
        />
        <Spinner open={isLoading} />
        {isLinkingTag && <ResponsiveDialog />}
        <DeleteAccountDialog open={isDeleting} onClose={() => setIsDeleting(false)} />
      </div>
    </div>
  );
};

export default MyPet;
