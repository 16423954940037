import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { resetLinking } from '../../redux/userSlice';
import { useDispatch, useSelector } from 'react-redux';
import { setPet } from "../../redux/petSlice";
import Spinner from "../Utilities/Spinner";
import { Banner } from "../Utilities/Snackbar";
import { top100Breeds, top100CatBreeds, animalTypes, capitalize } from "../Utilities/Utils";
import { red } from '@mui/material/colors'

export default function ResponsiveDialog() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const qrCodeId = searchParams.get("id");
  const [open, setOpen] = useState(true);
  const { firstName, email } = useSelector((state) => state.user.userInfo);
  const [isLoading, setIsLoading] = useState(false);
  const [petData, setPetData] = useState({
    petType: '',
    petName: '',
    petBreed: '',
  });
  const [isFormValid, setIsFormValid] = useState(false);
  const [touchedFields, setTouchedFields] = useState({
    petType: false,
    petName: false,
  });
  const [isPetInfoValid, setIsPetInfoValid] = useState(true);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  useEffect(() => {
    if (touchedFields.petType) {
      setIsPetInfoValid(animalTypes.includes(capitalize(petData.petType)));
    }
  }, [petData.petType, touchedFields.petType]);

  const dispatch = useDispatch();


  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const getAnimalBreed = (type) => {
    return type === 'Dog' ? top100Breeds : top100CatBreeds;
  };

  const setBreedValue = () => {
    if (petData.petBreed === "undefined")
      return '';
    return !getAnimalBreed(petData.petType).includes(petData.petBreed) ? '' : petData.petBreed;
  };

  const handleInputChangePet = (event) => {
    const { name, value } = event.target;
    setPetData((prevData) => ({ ...prevData, [name]: value }));
    setTouchedFields((prevTouched) => ({ ...prevTouched, [name]: true }));
    validateForm({ ...petData, [name]: value });
  };

  const validateForm = (data) => {
    const isValid = data.petType && data.petName;
    setIsFormValid(isValid);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const allPetsResponse = await axios.get(`${global.config.serverUrl}/getAllPets/`, {
        headers: {
          'email': email,
          'token': localStorage.getItem("loginToken")
        },
      });

      if (allPetsResponse.data) {
        dispatch(setPet(allPetsResponse.data));
        setIsLoading(false);
      } else {
        throw new Error("Failed to load all pets data");
      }
    } catch (error) {
      setSnackbarMessage("An error has occurred while loading your data...");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      setIsLoading(false);
    }
  };

  const linkTag = async () => {
    setIsLoading(true);
    try {
      await axios.post(
        `${global.config.serverUrl}/postPet/`,
        {
          petType: capitalize(petData.petType),
          petBreed: petData.petBreed === '' ? null : capitalize(petData.petBreed),
          petName: capitalize(petData.petName),
        },
        {
          headers: {
            id: qrCodeId,
            email: email,
            token: localStorage.getItem("loginToken"),
          },
        }
      );
      setSnackbarOpen(true);
      setSnackbarSeverity("success");
      setSnackbarMessage("Tag added to account!");
      fetchData();
      dispatch(resetLinking());
      navigate("/manage-pets");
    } catch (error) {
      setSnackbarMessage("Error saving user data. Please try again.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      setIsLoading(false);
    }
  };
  

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {`Link tag to ${firstName}'s account`}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please provide your pet's information to link the tag.
          </DialogContentText>
          <TextField
            name="petType"
            variant="outlined"
            fullWidth
            margin="normal"
            value={petData.petType ?? ''}
            onChange={handleInputChangePet}
            onBlur={() => setTouchedFields((prev) => ({ ...prev, petType: true }))}
            error={touchedFields.petType && !isPetInfoValid}
            helperText={
              touchedFields.petType && !isPetInfoValid
                ? "Invalid pet type. Please select a valid type."
                : ""
            }
            select
            SelectProps={{ native: true }}
          >
            <option value="" disabled>
              Select Pet Type
            </option>
            {animalTypes.map((type) => (
              <option key={type} value={type}>
                {type}
              </option>
            ))}
          </TextField>
          <TextField
            label="Pet Name"
            name="petName"
            variant="outlined"
            fullWidth
            margin="normal"
            value={petData.petName ?? ''}
            onChange={handleInputChangePet}
            onBlur={() => setTouchedFields((prev) => ({ ...prev, petName: true }))}
            error={touchedFields.petName && !petData.petName}
            helperText={
              touchedFields.petName && !petData.petName
                ? "Pet name is required."
                : ""
            }
          />
          <Autocomplete
            options={getAnimalBreed(petData.petType)}
            freeSolo
            value={setBreedValue()}
            onChange={(event, newValue) => {
              setPetData((prevData) => ({
                ...prevData,
                petBreed: newValue,
              }));
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Pet Breed"
                variant="outlined"
                fullWidth
                margin="normal"
              />
            )}
          />
        </DialogContent>
        <DialogActions>
        <Button
            sx={{backgroundColor: red[800], color: '#fff'}}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
          sx={{backgroundColor: '#476569', color: '#fff'}}
            onClick={linkTag}
            disabled={!isFormValid}
          >
            Link tag
          </Button>
        </DialogActions>
      </Dialog>
      <Banner
          open={snackbarOpen}
          message={snackbarMessage}
          severity={snackbarSeverity}
          handleClose={handleSnackbarClose}
        />
        <Spinner open={isLoading} />
    </React.Fragment>
  );
}
