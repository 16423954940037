import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setUser, setLinking } from '../redux/userSlice';
import { useSearchParams, useNavigate, Navigate } from 'react-router-dom';
import logo from '../components/assets/logo-pet.png';
import Spinner from '../components/Utilities/Spinner';
import { emailRegex } from '../components/Utilities/Utils';
import '../css/Login.css';
import axios from 'axios';
import '../config';
import { Banner } from '../components/Utilities/Snackbar';
import { FormControl, InputLabel, OutlinedInput, InputAdornment, IconButton, TextField } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { isAuthenticated } from '../components/Utilities/Authentification';

const LinkTag = () => {
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailTouched, setEmailTouched] = useState(false);
  const [passwordTouched, setPasswordTouched] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isAuthDone, setIsAuthDone] = useState(false);
  const [isAuth, setIsAuth] = useState(false);

  let { reduxEmail } = useSelector((state) => state.user.userInfo);

  useEffect(() => {
    (async () => {
      setIsAuth(await isAuthenticated(reduxEmail));
      setIsAuthDone(true);
    }
    )();
  }, []);
  if (!isAuthDone)
    return <Spinner open={true} />;
  if (isAuth)
    return <Navigate to={"/my-pet"} />;

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    let token = "";
    let firstName = "";
    let lastName = "";

    try {
      const res = await axios.post(`${global.config.serverUrl}/login/`, {
        email: email,
        password: password,
      });

      token = res.data.token;
      firstName = res.data.firstName;
      lastName = res.data.lastName;

      setIsLoading(false);
      setSnackbarMessage('Login successful! Redirecting...');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (error) {
      setIsLoading(false);
      setSnackbarMessage('Incorrect email or password.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      return;
    }

    localStorage.setItem('loginToken', token);
    const userData = {
      email,
      firstName,
      lastName,
      token,
    };
    dispatch(setUser(userData));
    dispatch(setLinking());
    navigate(`/my-pet?id=${id}`);

  };



  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    } 
    setSnackbarOpen(false);
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const isEmailValid = emailRegex.test(email);
  const isPasswordValid = password.length >= 6;
  const isFormValid = isEmailValid && isPasswordValid;

  return (
    <div className="login-container">
      <div className="login-form">
        <h1 className='login-title'>Login to proceed with linking your tag</h1>
        <img style={{ width: '320px', height: '420px', marginTop: '-130px', marginBottom: '-150px' }} src={logo} alt='Logo' />
        <form onSubmit={handleSubmit}>
          <div className="input-container">
            <TextField
              fullWidth
              label="Email"
              variant="outlined"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
                setEmailTouched(true);
              }}
              onBlur={() => setEmailTouched(true)}
              error={emailTouched && !emailRegex.test(email)}
              helperText={emailTouched && !emailRegex.test(email) ? 'Invalid email address' : ''}
            />
          </div>
          <div className="input-container">
            <FormControl fullWidth variant="outlined">
              <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                type={showPassword ? 'text' : 'password'}
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                  setPasswordTouched(true);
                }}
                onBlur={() => setPasswordTouched(true)}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Password"
                error={passwordTouched && password.length < 6}
              />
              {passwordTouched && password.length < 6 && (
                <p className="error-message">Password must be at least 6 characters</p>
              )}
            </FormControl>
          </div>
          <button style={{ marginLeft: '-10px' }} type="submit" disabled={!isFormValid}>Login</button>
        </form>
        <p className="signup-link">
          Don't have an account? <a href="/order">Order your tag now!</a>
        </p>
        <p className="signup-link">
          <a href="/reset-password">Forgotten password?</a>
        </p>
      </div>
      <Spinner open={isLoading} />
      <Banner
        open={snackbarOpen}
        handleClose={handleSnackbarClose}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </div>
  );
};

export default LinkTag;
