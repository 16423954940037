"use client";
import React, { useState } from "react";
import { APIProvider, Map, AdvancedMarker } from '@vis.gl/react-google-maps';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Banner } from "./Utilities/Snackbar";
import { red } from "@mui/material/colors";

export function GoogleMapsViewer({ coordinates }) {
    return (
        <APIProvider apiKey='AIzaSyABvTUVRcAhy5yUleF1ZdL-DMAaSTai908'>
            {
                coordinates.lat && coordinates.lng
                    ?
                    <div style={{ height: '50vh' }}>
                        <Map defaultZoom={11} center={coordinates} mapId={"Map.DEMO_MAP_ID"} >
                            <AdvancedMarker
                                position={{
                                    lat: coordinates.lat,
                                    lng: coordinates.lng
                                }}
                            />
                        </Map>
                    </div>
                    :
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '10vh' }}>
                        {'Please provide a city so we can locate you automatically on the map'}
                    </div>
            }
        </APIProvider>
    );
}

export function GoogleMapsCoordsGetter({ data, dataSetter }) {

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");

    const handleSnackbarClose = (event, reason) => {
        if (reason === "clickaway") {
          return;
        }
        setSnackbarOpen(false);
      };


    const clearCoordinates = () => {
        dataSetter({
            ...data,
            lat: null,
            lng: null
        });
        setSnackbarMessage(
            "Location successfully cleared."
          );
          setSnackbarSeverity("success");
          setSnackbarOpen(true);
    };
    return (
        <APIProvider apiKey='AIzaSyABvTUVRcAhy5yUleF1ZdL-DMAaSTai908'>
            <Typography variant="body1" gutterBottom>
                Press on the map to point to your desired location.
            </Typography>

            <Map
                style={{
                    width: '100%',
                    height: '50vh',
                }}
                mapId={"Map.DEMO_MAP_ID"}
                defaultCenter={{
                    lat: data.lat === null || data.lat === 0 ? 33.8938 : data.lat,
                    lng: data.lat === null || data.lat === 0 ? 35.5018 : data.lng
                }}
                defaultZoom={11}
                onClick={(e) => {
                    dataSetter({
                        ...data,
                        lat: e.detail.latLng.lat,
                        lng: e.detail.latLng.lng
                    });
                }}
            >
                {
                    data.lat !== null && data.lng !== null &&
                    <AdvancedMarker
                        position={{
                            lat: data.lat,
                            lng: data.lng
                        }}
                    />
                }
            </Map>
            <Button
                variant="outlined"
                color="secondary"
                style={{ marginTop: '10px', backgroundColor: red[800], color: 'white' }}
                onClick={clearCoordinates}
            >
                Clear Location
            </Button>
        <Banner
          open={snackbarOpen}
          message={snackbarMessage}
          severity={snackbarSeverity}
          handleClose={handleSnackbarClose}
        />
        </APIProvider>
    );
}