import React, { useState, useEffect } from "react";
import axios from "axios";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useSelector } from 'react-redux';
import Spinner from "../Utilities/Spinner";
import { Banner } from "../Utilities/Snackbar";
import { red } from '@mui/material/colors';

export default function UnlinkPetDialog({ open, onClose, qrCode, fetchPets }) { 
    const { email } = useSelector((state) => state.user.userInfo);
    const [isLoading, setIsLoading] = useState(false);
  
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  
    const handleSnackbarClose = (event, reason) => {
      if (reason === "clickaway") {
        return;
      }
      setSnackbarOpen(false);
    };
  
    const unlinkTag = async () => {
      setIsLoading(true);
      try {
        await axios.delete(
          `${global.config.serverUrl}/deletePet/`,
          {
            headers: {
              'id': qrCode,
              'email': email,
              'token': localStorage.getItem("loginToken")
            },
          }
        );
        setSnackbarOpen(true);
        setSnackbarSeverity("success");
        setSnackbarMessage("Your tag was unlinked successfully!");
        
        fetchPets();
        
        onClose();
        setIsLoading(false);
      } catch (error) {
        setSnackbarMessage("Error unlinking tag. Please try again.");
        setSnackbarSeverity("error");
        onClose();
        setSnackbarOpen(true);
        setIsLoading(false);
      }
    };
  
    return (
      <React.Fragment>
        <Dialog
          open={open}
          onClose={onClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">
            Are you sure you want to unlink this tag from your account?
          </DialogTitle>
          <DialogContent>
            <DialogContentText sx={{mt: 2, fontSize: '16px'}}>
              You can re-link this tag to your account anytime.
            </DialogContentText>
          </DialogContent>
           
          <DialogActions>
            <Button
              sx={{backgroundColor: '#476569', color: '#fff'}}
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              sx={{backgroundColor: red[800], color: '#fff'}}
              onClick={unlinkTag}
            >
              Unlink
            </Button>
          </DialogActions>
        </Dialog>
        <Banner
          open={snackbarOpen}
          message={snackbarMessage}
          severity={snackbarSeverity}
          handleClose={handleSnackbarClose}
        />
        <Spinner open={isLoading} />
      </React.Fragment>
    );
  }
  