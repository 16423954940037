import React from 'react';
import { BrowserRouter, Routes, Route  } from "react-router-dom";
import Login from './Pages/Login';
import CreateAccount from './Pages/CreateAccount';
import MyPet from './Pages/MyPet';
import ViewPet from './Pages/ViewPet';
import NotFound from './Pages/NotFound';
import AboutUs from './Pages/AboutUs';
import Order from './Pages/Order';
import ResetPassword from './Pages/ResetPassword';
import Verify from './Pages/Verify';
import LinkTag from './Pages/LinkTag';
import ManagePets from './Pages/ManagePets';

function App() {
  return (
<BrowserRouter>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/create-account" element={<CreateAccount />}/>
        <Route path="/my-pet" element={<MyPet />} />
        <Route path="/manage-pets" element={<ManagePets />} />
        <Route path="/view-my-pet" element={<ViewPet />} />
        <Route path="/link-tag" element={<LinkTag />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/order" element={<Order />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/verify-account" element={<Verify />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
