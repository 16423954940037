import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useDispatch, useSelector } from 'react-redux';
import Spinner from "../Utilities/Spinner";
import { Banner } from "../Utilities/Snackbar";
import { red } from '@mui/material/colors';

export default function DeleteAccountDialog({ open, onClose }) { 
  const navigate = useNavigate();
  const { email } = useSelector((state) => state.user.userInfo);
  const [isLoading, setIsLoading] = useState(false);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  const dispatch = useDispatch();

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const deleteAccount = async () => {
    setIsLoading(true);
    try {
      await axios.delete(
        `${global.config.serverUrl}/deleteOwner/`,
        {
          headers: {
            'email': email,
            'token': localStorage.getItem("loginToken")
          },
        }
      );
      setSnackbarOpen(true);
      setSnackbarSeverity("success");
      setSnackbarMessage("Account successfully deleted!");
      onClose();
      setTimeout(() => {
        navigate(`/about-us`);
      }, 3000);
   
    } catch (error) {
      setSnackbarMessage("Error deleting account. Please try again.");
      setSnackbarSeverity("error");
      onClose();
      setSnackbarOpen(true);
      setIsLoading(false);
    }
  };

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          Are you sure you want to delete this account?
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            All linked tags and credentials will no longer be valid.
          </DialogContentText>

          <DialogContentText sx={{mt: 2, fontSize: '14px'}}>
            You can always re-create an account by scanning your QR code.
          </DialogContentText>
        </DialogContent>
         
        <DialogActions>
          <Button
            sx={{backgroundColor: '#476569', color: '#fff'}}
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            sx={{backgroundColor: red[800], color: '#fff'}}
            onClick={deleteAccount}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Banner
        open={snackbarOpen}
        message={snackbarMessage}
        severity={snackbarSeverity}
        handleClose={handleSnackbarClose}
      />
      <Spinner open={isLoading} />
    </React.Fragment>
  );
}
