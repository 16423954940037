export const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

export const phoneRegex = /^\+?[0-9]{8,15}$/;

export const getFirstLetter = (str) => {
  if (str && typeof str === 'string') {
    return str.charAt(0);
  }
  return '';
};

export function capitalize(string) {
  if (typeof (string) === 'undefined' || string === null)
    return null;
  const words = string.split(' ');
  for (let i = 0; i < words.length; i++) {
    try {
      words[i] = words[i][0].toUpperCase() + words[i].substr(1);
    }
    catch {
    }
  }
  return words.join(' ');
}

export const animalTypes = [
  'Dog',
  'Cat',
];

export const top100Breeds = [
  'Other',
  'Labrador Retriever',
  'German Shepherd',
  'Golden Retriever',
  'French Bulldog',
  'Bulldog',
  'Poodle',
  'Beagle',
  'Rottweiler',
  'Yorkshire Terrier',
  'Boxer',
  'Dachshund',
  'Siberian Husky',
  'Pembroke Welsh Corgi',
  'Doberman Pinscher',
  'Shih Tzu',
  'Australian Shepherd',
  'Chihuahua',
  'Pug',
  'Border Collie',
  'Boston Terrier',
  'Miniature Schnauzer',
  'Cocker Spaniel',
  'Shetland Sheepdog',
  'Great Dane',
  'Bichon Frise',
  'Pomeranian',
  'Havanese',
  'Maltese',
  'Saint Bernard',
  'Bull Terrier',
  'Akita',
  'Weimaraner',
  'Newfoundland',
  'Irish Setter',
  'Australian Cattle Dog',
  'Bernese Mountain Dog',
  'Alaskan Malamute',
  'Bloodhound',
  'Lhasa Apso',
  'Soft Coated Wheaten Terrier',
  'Jack Russell Terrier',
  'Scottish Terrier',
  'Whippet',
  'Basenji',
  'Shiba Inu',
  'Pekingese',
  'Cane Corso',
  'Papillon',
  'Fox Terrier',
  'Airedale Terrier',
  'English Pointer',
  'American Staffordshire Terrier',
  'Cavalier King Charles Spaniel',
  'Vizsla',
  'Belgian Malinois',
  'Staffordshire Bull Terrier',
  'Great Pyrenees',
  'English Springer Spaniel',
  'Brittany Spaniel',
  'Rhodesian Ridgeback',
  'Chesapeake Bay Retriever',
  'Dalmatian',
  'Basset Hound',
  'Collie',
  'American Bulldog',
  'Samoyed',
  'Bullmastiff',
  'Cairn Terrier',
  'Old English Sheepdog',
  'Afghan Hound',
  'Chinese Crested'
];


export const top100CatBreeds = [
  'Other',
  'Maine Coon',
  'Siamese',
  'Persian',
  'Ragdoll',
  'Bengal',
  'British Shorthair',
  'Sphynx',
  'Abyssinian',
  'Scottish Fold',
  'Norwegian Forest',
  'Siberian',
  'Russian Blue',
  'Burmese',
  'Devon Rex',
  'Cornish Rex',
  'Oriental Shorthair',
  'Himalayan',
  'Manx',
  'Savannah',
  'Chartreux',
  'Somali',
  'Egyptian Mau',
  'Tonkinese',
  'Turkish Angora',
  'Turkish Van',
  'Munchkin',
  'Selkirk Rex',
  'LaPerm',
  'Singapura',
  'American Shorthair',
  'Briard',
  'Balinese',
  'Birman',
  'Burmilla',
  'Cymric',
  'Donskoy',
  'European Shorthair',
  'Japanese Bobtail',
  'Khao Manee',
  'Korat',
  'Lykoi',
  'Ocicat',
  'Ojos Azules',
  'Pixie Bob',
  'Ragamuffin',
  'Snowshoe',
  'American Curl',
  'Asian',
  'Bambino',
  'British Longhair',
  'Coon',
];
