import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Dropzone from "react-dropzone";
import Avatar from "@mui/material/Avatar";
import { styled } from "@mui/material/styles";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import { Banner } from "./Utilities/Snackbar";
import Spinner from "./Utilities/Spinner";
import { red } from "@mui/material/colors";
import axios from "axios";

const AvatarContainer = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  position: "relative",
});

export const StyledAvatar = styled(Avatar)({
  width: Math.min(window.innerWidth - 90, 250),
  height: Math.min(window.innerWidth - 90, 250),
  cursor: "pointer",
  border: "2px solid #B4D479",
});

export const StyledAvatarManagePets = styled(Avatar)({
  width: Math.min(window.innerWidth - 90, 90),
  height: Math.min(window.innerWidth - 90, 90),
  cursor: "pointer",
  border: "2px solid #B4D479",
});

const DeleteButton = styled(IconButton)({
  position: "absolute",
  top: "10px",
  right: "10px",
  color: "#fff",
  backgroundColor: red[800],
  border: "2px solid #fff",
  width: "40px",
  height: "40px",
  borderRadius: "50%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

function AvatarUpload({ qrCodeId }) {
  const [avatar, setAvatar] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const { email } = useSelector((state) => state.user.userInfo);
  const [isLoading, setIsLoading] = useState(false);

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };


  const handleDelete = async () => {
    try {
      setIsLoading(true);
      await axios.delete(`${global.config.serverUrl}/deletePic/`, {
        headers: {
          'id': qrCodeId,
          'token': localStorage.getItem("loginToken"),
          'email': email
        },
      });
      setAvatar(null);
      setSnackbarMessage('Image removed successfully!');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
      setIsLoading(false);
    } catch (error) {
      setSnackbarMessage('An error has occurred, please try again.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      setIsLoading(false);
    }
  };

  const handleDrop = (acceptedFiles) => {
    setIsLoading(true);
    const file = acceptedFiles[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = async () => {
        setAvatar(reader.result);
  
        try {
          await axios.post(
            `${global.config.serverUrl}/postPic/`,
            file, {
            headers: {
              'Content-Type': 'image/*',
              'id': qrCodeId,
              'token': localStorage.getItem("loginToken"),
              'email': email
            },
          });
  
          setSnackbarMessage('Image uploaded successfully!');
          setSnackbarSeverity('success');
          setSnackbarOpen(true);
  
          let res = await axios.get(`${global.config.serverUrl}/getPic/`, {
            headers: {
              'id': qrCodeId
            },
            responseType: "arraybuffer",
          });
          let blob = new Blob([res.data], { type: res.headers["Content-Type"] });
          let imageUrl = URL.createObjectURL(blob);
          setAvatar(imageUrl);
  
          setIsLoading(false);
        } catch (error) {
          setSnackbarMessage('An error has occurred, please try again.');
          setSnackbarSeverity('error');
          setSnackbarOpen(true);
          setIsLoading(false);
        }
      };
    }
  };
  
  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        let res = await axios.get(`${global.config.serverUrl}/getPic/`, {
          headers: {
            'id': qrCodeId
          },
          responseType: "arraybuffer",
        });
        let blob = new Blob([res.data], { type: res.headers["Content-Type"] });
        let imageUrl = URL.createObjectURL(blob);
        setAvatar(imageUrl);
        setIsLoading(false);
      } catch {
        setIsLoading(false);
      }
    })();
  }, [qrCodeId]);
  

  const acceptedFiles = {
    'image/*': []
  };

  return (
    <>
      <Dropzone onDrop={handleDrop} accept={acceptedFiles}>
        {({ getRootProps, getInputProps }) => (
          <AvatarContainer>
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              <StyledAvatar src={avatar} alt="Avatar">
                {!avatar && <AddAPhotoIcon fontSize="large" />}
              </StyledAvatar>
            </div>
            {avatar && (
              <DeleteButton onClick={handleDelete}>
                <DeleteIcon />
              </DeleteButton>
            )}
          </AvatarContainer>
        )}
      </Dropzone>
      <Banner
        open={snackbarOpen}
        handleClose={handleSnackbarClose}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
      <Spinner open={isLoading} />
    </>
  );
}

export default AvatarUpload;
