import React, { useEffect, useRef, useState } from "react";
import { Paper, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: "20px",
  backgroundColor: "#B4D479",
  textAlign: "center",
  opacity: 0,
  transform: "translateY(20px) scale(0.95)",
  transition: "opacity 1s ease-out, transform 1s ease-out", // Increased from 0.5s to 1s
  transitionDelay: "1.2s", // Increased from 0.8s to 1.2s
  "&.visible": {
    opacity: 1,
    transform: "translateY(0) scale(1)",
    transition: "opacity 0.3s ease-in-out, transform 1.2s ease-in-out", // Increased transform transition time
  },
}));


const VisionMissionValues = ({ title, content }) => {
  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef();

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        }
      },
      { threshold: 0.1 }
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, []);

  return (
    <StyledPaper
      ref={ref}
      className={isVisible ? "visible" : ""}
      sx={{minHeight: '80px'}}
    >
      <Typography
        variant="h6"
        style={{ fontFamily: "Arial, sans-serif", fontWeight: "bold" }}
      >
        {title}
      </Typography>
      <Typography
        variant="body1"
        style={{ fontFamily: "Arial, sans-serif" }}
      >
        {content}
      </Typography>
    </StyledPaper>
  );
};

export default VisionMissionValues;
