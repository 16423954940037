import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

export default function Spinner({ open }) {
  return (
    <Backdrop
      sx={{ color: '#B4D479', zIndex: 999999 }}
      open={open}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
}
