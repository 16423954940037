import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Box,
  Container,
  Grid,
  Card,
  CardContent,
  Typography,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  useTheme,
  useMediaQuery,
  Drawer,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, Navigate } from "react-router-dom";
import { PopupBar } from "../components/Utilities/PopupBar";
import { clearUser, resetLinking } from "../redux/userSlice";
import { clearPet } from "../redux/petSlice";
import { red } from "@mui/material/colors";
import Spinner from "../components/Utilities/Spinner";
import LogoutIcon from "@mui/icons-material/Logout";
import DeleteIcon from "@mui/icons-material/Delete";
import PetsIcon from "@mui/icons-material/Pets";
import EditIcon from "@mui/icons-material/Edit";
import LinkOffIcon from "@mui/icons-material/LinkOff";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Banner } from "../components/Utilities/Snackbar";
import logo from "../components/assets/logo-pet.png";
import UnlinkPetDialog from "../components/Dialog/UnlinkPetDialog";
import { isAuthenticated } from "../components/Utilities/Authentification";
import DeleteAccountDialog from "../components/Dialog/DeleteAccountDialog";
import { StyledAvatarManagePets } from "../components/AvatarUpload";

const ManagePets = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const ownerInfo = useSelector((state) => state.user.userInfo);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedQRCodeId, setSelectedQRCodeId] = useState("");
  const [isUnlinking, setIsUnlinking] = useState(false);
  const [petInfo, setPet] = useState([]);
  const [avatar, setAvatar] = useState(null);

  const [isAuthDone, setIsAuthDone] = useState(false);
  const [isAuth, setIsAuth] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  const dogs = petInfo.filter((pet) => pet.petType === "Dog");
  const cats = petInfo.filter((pet) => pet.petType === "Cat");

  const showDeleteDialog = () => {
    setIsDeleting(true);
  };

  const fetchPets = async () => {
    setIsLoading(true);
    try {
      const allPetsResponse = await axios.get(
        `${global.config.serverUrl}/getAllPets/`,
        {
          headers: {
            email: ownerInfo.email,
            token: localStorage.getItem("loginToken"),
          },
        }
      );

      const pets = allPetsResponse.data;

      const petsWithAvatars = await Promise.all(
        pets.map(async (pet) => {
          const id = pet.qrCodeId;

          if (id) {
            try {
              let res = await axios.get(`${global.config.serverUrl}/getPic/`, {
                headers: { id: id },
                responseType: "arraybuffer",
              });

              let blob = new Blob([res.data], {
                type: res.headers["Content-Type"],
              });
              let imageUrl = URL.createObjectURL(blob);

              return { ...pet, avatar: imageUrl };
            } catch (error) {
              console.error(
                `Error fetching picture for pet with id ${id}:`,
                error
              );
              return { ...pet, avatar: null };
            }
          }

          return { ...pet, avatar: null };
        })
      );

      setPet(petsWithAvatars);
    } catch (error) {
      setSnackbarMessage("An error has occurred while loading your data...");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchPets();
  }, [ownerInfo.email, dispatch]);

  useEffect(() => {
    (async () => {
      setIsAuth(await isAuthenticated(ownerInfo.email));
      setIsAuthDone(true);
    })();
  }, [ownerInfo.email]);

  if (!isAuthDone) return <Spinner open={true} />;
  if (!isAuth) return <Navigate to={"/"} />;

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const backMyPet = () => {
    navigate("/my-pet");
  };

  const logout = () => {
    dispatch(clearUser());
    dispatch(clearPet());
    dispatch(resetLinking());
    localStorage.removeItem("loginToken");
    navigate("/");
  };

  const handleEdit = (petId) => {
    navigate(`/my-pet?id=${petId}`);
  };

  const showDelete = async (qrCodeId) => {
    setIsUnlinking(true);
    setSelectedQRCodeId(qrCodeId);
  };

  return (
    <Box
      display="flex"
      bgcolor="#f5f5dc"
      color="#333"
      minHeight="100vh"
      flexDirection={isMobile ? "column" : "row"}
    >
      <Drawer
        variant="permanent"
        sx={{
          width: "60px",
          "& .MuiDrawer-paper": {
            backgroundColor: "#B4D479",
            color: "#fff",
            flexDirection: "column-reverse",
          },
        }}
      >
        <PopupBar
          buttons={[
            [
              { value: "My Pet", onClick: backMyPet, icon: <PetsIcon /> },
              { value: "Logout", onClick: logout, icon: <LogoutIcon /> },
            ],
            [
              {
                value: "Delete Account",
                onClick: showDeleteDialog,
                icon: <DeleteIcon />,
                color: red[800],
              },
            ],
            ,
          ]}
          firstName={ownerInfo.firstName}
          lastName={ownerInfo.lastName}
        />
      </Drawer>

      <Box
        id="main-content"
        padding={isMobile ? "10px" : "20px"}
        flex={1}
        display="flex"
        flexDirection="column"
        alignItems="center"
        marginLeft={isMobile ? "50px" : "60px"}
        marginTop="20px"
      >
        <Container maxWidth="lg">
          {!isLoading && (
            <>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                mb={-17}
              >
                <h1 className="login-title" style={{ marginBottom: "-100px" }}>
                  Manage Pets
                </h1>
                <img
                  style={{ width: "320px", height: "420px" }}
                  src={logo}
                  alt="Logo"
                />
              </Box>

              {dogs.length > 0 && (
                <Accordion
                  sx={{
                    mb: 2,
                    bgcolor: "#ffffff",
                    boxShadow: 6,
                    borderRadius: 5,
                    "&:before": {
                      display: "none",
                    },
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    sx={{
                      bgcolor: "#B4D479",
                      color: "#fff",
                      "&.Mui-expanded": {
                        bgcolor: "#B4D479",
                        color: "#fff",
                      },
                      transition: "background-color 0.3s ease",
                    }}
                  >
                    <Typography
                      variant="h6"
                      sx={{
                        color: "#333",
                        fontWeight: "bold",
                      }}
                    >
                      My Dogs
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={3} justifyContent="center">
                      {dogs.length === 0 && !isLoading ? (
                        <Typography
                          variant="h6"
                          align="center"
                          sx={{ color: "#333" }}
                        >
                          No dogs available.
                        </Typography>
                      ) : (
                        dogs.map((pet) => (
                          <Grid item xs={12} sm={6} md={4} key={pet.qrCodeId}>
                            <Card
                              sx={{
                                maxWidth: 300,
                                bgcolor: "#ffffff",
                                boxShadow: 6,
                                borderRadius: 5,
                                transition:
                                  "transform 0.3s ease, box-shadow 0.3s ease",
                                "&:hover": {
                                  transform: "scale(1.05)",
                                  boxShadow: "0 8px 16px rgba(0,0,0,0.2)",
                                },
                              }}
                            >
                              <CardContent
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: 1.5,
                                  padding: 3,
                                  bgcolor: "#fff",
                                  borderRadius: 4,
                                  transition: "background-color 0.3s ease",
                                  "&:hover": {
                                    backgroundColor: "#f0f0f0",
                                  },
                                }}
                              >
                                <Box
                                  display="flex"
                                  justifyContent="space-between"
                                  alignItems="center"
                                  gap={1.5}
                                >
                                  <Typography
                                    variant="h6"
                                    sx={{
                                      color: "#476569",
                                      fontWeight: "bold",
                                      mb: 1,
                                    }}
                                  >
                                    {pet.petName}
                                  </Typography>
                                  <StyledAvatarManagePets
                                    src={pet.avatar}
                                    alt="Avatar"
                                    sx={{ ml: 2 }}
                                  />
                                </Box>

                                <Box
                                  display="flex"
                                  flexDirection="column"
                                  gap={1.5}
                                >
                                  <Typography variant="body2">
                                    <strong>Type:</strong> {pet.petType}
                                  </Typography>
                                  <Typography variant="body2">
                                    <strong>Breed:</strong>{" "}
                                    {pet.petBreed || "-"}
                                  </Typography>
                                </Box>

                                <Box
                                  display="flex"
                                  flexDirection="column"
                                  gap={1}
                                  alignItems="center"
                                >
                                  <Button
                                    onClick={() => handleEdit(pet.qrCodeId)}
                                    variant="outlined"
                                    color="primary"
                                    size="small"
                                    sx={{
                                      borderColor: "#476569",
                                      color: "#fff",
                                      backgroundColor: "#476569",
                                      textTransform: "none",
                                      "&:hover": {
                                        backgroundColor: "#BDC3C7",
                                        color: "#fff",
                                      },
                                      "&:disabled": {
                                        borderColor: "#BDC3C7",
                                        color: "#BDC3C7",
                                      },
                                    }}
                                    endIcon={<EditIcon />}
                                  />
                                  <Button
                                    disabled={petInfo.length <= 1}
                                    onClick={() => showDelete(pet.qrCodeId)}
                                    variant="outlined"
                                    color="secondary"
                                    size="small"
                                    sx={{
                                      borderColor: "none",
                                      color: "#fff",
                                      backgroundColor: red[800],
                                      textTransform: "none",
                                      "&:disabled": {
                                        borderColor: "#BDC3C7",
                                        color: "#BDC3C7",
                                      },
                                    }}
                                    endIcon={<LinkOffIcon />}
                                  ></Button>
                                  {petInfo.length <= 1 && (
                                    <span
                                      style={{
                                        fontSize: "12px",
                                        color: red[800],
                                      }}
                                    >
                                      You can only unlink a tag when at least
                                      two pets are added.
                                    </span>
                                  )}
                                </Box>
                              </CardContent>
                            </Card>
                          </Grid>
                        ))
                      )}
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              )}
              {cats.length > 0 && (
                <Accordion
                  sx={{
                    mb: 2,
                    bgcolor: "#ffffff",
                    boxShadow: 6,
                    borderRadius: 5,
                    "&:before": {
                      display: "none",
                    },
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                    sx={{
                      bgcolor: "#B4D479",
                      color: "#fff",
                      "&.Mui-expanded": {
                        bgcolor: "#B4D479",
                        color: "#fff",
                      },
                      transition: "background-color 0.3s ease",
                    }}
                  >
                    <Typography
                      variant="h6"
                      sx={{
                        color: "#333",
                        fontWeight: "bold",
                      }}
                    >
                      My Cats
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={3} justifyContent="center">
                      {cats.length === 0 && !isLoading ? (
                        <Typography
                          variant="h6"
                          align="center"
                          sx={{ color: "#333" }}
                        >
                          No cats available.
                        </Typography>
                      ) : (
                        cats.map((pet) => (
                          <Grid item xs={12} sm={6} md={4} key={pet.qrCodeId}>
                            <Card
                              sx={{
                                maxWidth: 300,
                                bgcolor: "#ffffff",
                                boxShadow: 6,
                                borderRadius: 5,
                                transition:
                                  "transform 0.3s ease, box-shadow 0.3s ease",
                                "&:hover": {
                                  transform: "scale(1.05)",
                                  boxShadow: "0 8px 16px rgba(0,0,0,0.2)",
                                },
                              }}
                            >
                              <CardContent
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: 1.5,
                                  padding: 3,
                                  bgcolor: "#fff",
                                  borderRadius: 4,
                                  transition: "background-color 0.3s ease",
                                  "&:hover": {
                                    backgroundColor: "#f0f0f0",
                                  },
                                }}
                              >
                                <Box
                                  display="flex"
                                  justifyContent="space-between"
                                  alignItems="center"
                                  gap={1.5}
                                >
                                  <Typography
                                    variant="h6"
                                    sx={{
                                      color: "#476569",
                                      fontWeight: "bold",
                                      mb: 1,
                                    }}
                                  >
                                    {pet.petName}
                                  </Typography>
                                  <StyledAvatarManagePets
                                    src={pet.avatar}
                                    alt="Avatar"
                                    sx={{ ml: 2 }}
                                  />
                                </Box>

                                <Box
                                  display="flex"
                                  flexDirection="column"
                                  gap={1.5}
                                >
                                  <Typography variant="body2">
                                    <strong>Type:</strong> {pet.petType}
                                  </Typography>
                                  <Typography variant="body2">
                                    <strong>Breed:</strong>{" "}
                                    {pet.petBreed || "-"}
                                  </Typography>
                                </Box>

                                <Box
                                  display="flex"
                                  flexDirection="column"
                                  gap={1}
                                  alignItems="center"
                                >
                                  <Button
                                    onClick={() => handleEdit(pet.qrCodeId)}
                                    variant="outlined"
                                    color="primary"
                                    size="small"
                                    sx={{
                                      borderColor: "#476569",
                                      color: "#fff",
                                      backgroundColor: "#476569",
                                      textTransform: "none",
                                      "&:hover": {
                                        backgroundColor: "#BDC3C7",
                                        color: "#fff",
                                      },
                                      "&:disabled": {
                                        borderColor: "#BDC3C7",
                                        color: "#BDC3C7",
                                      },
                                    }}
                                    endIcon={<EditIcon />}
                                  />
                                  <Button
                                    disabled={petInfo.length <= 1}
                                    onClick={() => showDelete(pet.qrCodeId)}
                                    variant="outlined"
                                    color="secondary"
                                    size="small"
                                    sx={{
                                      borderColor: "none",
                                      color: "#fff",
                                      backgroundColor: red[800],
                                      textTransform: "none",
                                      "&:disabled": {
                                        borderColor: "#BDC3C7",
                                        color: "#BDC3C7",
                                      },
                                    }}
                                    endIcon={<LinkOffIcon />}
                                  ></Button>
                                  {petInfo.length <= 1 && (
                                    <span
                                      style={{
                                        fontSize: "12px",
                                        color: red[800],
                                      }}
                                    >
                                      You can only unlink a tag when at least
                                      two pets are added.
                                    </span>
                                  )}
                                </Box>
                              </CardContent>
                            </Card>
                          </Grid>
                        ))
                      )}
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              )}
            </>
          )}
          <Spinner open={isLoading} />
        </Container>
      </Box>
      <UnlinkPetDialog
        open={isUnlinking}
        onClose={() => setIsUnlinking(false)}
        qrCode={selectedQRCodeId}
        fetchPets={fetchPets}
      />

      <Banner
        open={snackbarOpen}
        onClose={handleSnackbarClose}
        severity={snackbarSeverity}
        message={snackbarMessage}
      />
      <DeleteAccountDialog
        open={isDeleting}
        onClose={() => setIsDeleting(false)}
      />
    </Box>
  );
};

export default ManagePets;
