import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { setUser } from '../redux/userSlice';
import { emailRegex, phoneRegex, animalTypes, capitalize } from '../components/Utilities/Utils';
import Autocomplete from "@mui/material/Autocomplete";
import logo from "../components/assets/logo-pet.png";
import '../config';
import {
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { Banner } from '../components/Utilities/Snackbar';
import '../css/CreateAccount.css';
import Spinner from '../components/Utilities/Spinner';

const CreateAccount = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [petType, setPetType] = useState('');
  const [petName, setPetName] = useState('');
  const [passwordStrength, setPasswordStrength] = useState('');
  const [emailError, setEmailError] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [isFormValid, setIsFormValid] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [searchParams] = useSearchParams();
  const qrCodeId = searchParams.get('id');

  const handlePasswordChange = (e) => {
    const pass = e.target.value;
    setPassword(pass);
  
    const hasUpperCase = /[A-Z]/.test(pass);
    const hasLowerCase = /[a-z]/.test(pass);
    const hasNumbers = /[0-9]/.test(pass);
    const hasSymbols = /[!@#$%^&*(),.?":{}|<>]/.test(pass);
    const isLongEnough = pass.length >= 12;
  
    if (pass.length === 0) {
      setPasswordStrength('');
      setPasswordError('');
    } else if (pass.length < 6) {
      setPasswordStrength('Weak');
      setPasswordError('Password must be at least 6 characters long.');
    } else if (pass.length < 12 || !(hasUpperCase && hasLowerCase && hasNumbers && hasSymbols)) {
      setPasswordStrength('Moderate');
      setPasswordError('');
    } else if (isLongEnough && hasUpperCase && hasLowerCase && hasNumbers && hasSymbols) {
      setPasswordStrength('Strong');
      setPasswordError('');
    }
  };
  

  useEffect(() => {
    const isValid =
      emailRegex.test(email) &&
      password.length >= 6 &&
      firstName &&
      lastName &&
      petName &&
      (phoneNumber === '' || phoneRegex.test(phoneNumber)) && 
      animalTypes.includes(capitalize(petType));
  
    setIsFormValid(isValid);
    setEmailError(email && !emailRegex.test(email) ? 'Invalid email format' : '');
    setPhoneError(phoneNumber && phoneNumber !== '' && !phoneRegex.test(phoneNumber) ? 'Invalid phone number format' : '');
  }, [email, password, firstName, lastName, petType, petName, phoneNumber]);
  

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isFormValid) {
      setIsLoading(true);
      let token = "";
      try {
        const res = await axios.post(`${global.config.serverUrl}/register/`,
          {
            email: email,
            password: password
          },
          {
            headers: {
              'id': qrCodeId
            },
          }
        );
        token = res.data.token;

        localStorage.setItem('loginToken', token);
        const userData = { email: email, token: token };
        dispatch(setUser(userData));

        try {
          await axios.post(`${global.config.serverUrl}/postOwner/`,
            {
              firstName: capitalize(firstName),
              lastName: capitalize(lastName),
              phoneNumber: phoneNumber,
            },
            {
              headers: {
                'email': email,
                'token': token
              },
            }
          );
          await axios.post(`${global.config.serverUrl}/postPet/`,
            {
              petType: capitalize(petType),
              petName: capitalize(petName),
            },
            {
              headers: {
                'id': qrCodeId,
                'email': email,
                'token': token
              },
            }
          );
          setSnackbarMessage('Account succesfully created!');
          setSnackbarSeverity('success');
          setSnackbarOpen(true);
          const userData = {
            email,
            firstName,
            lastName,
            token,
          };
          dispatch(setUser(userData));
          setTimeout(() => {
            setIsLoading(false);
            navigate('/my-pet');
          }, 1000);
        } catch (error) {
          setSnackbarMessage('Error creating account. Please try again.');
          setSnackbarSeverity('error');
          setSnackbarOpen(true);
          setIsLoading(false);
          return;
        }
      } catch (error) {
        setSnackbarMessage('Error creating account. Please try again.');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
        setIsLoading(false);
        return;
      }
    }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  return (
    <div className="create-account-container">
      <div className="create-account-form">
        <h1 className='create-title'>Create your account</h1>
        <img style={{ width: '320px', height: '420px', marginTop: '-130px', marginBottom: '-150px' }} src={logo} alt='Logo' />
        <form style={{ marginTop: '20px' }} onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                label="First Name"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Last Name"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                fullWidth
                error={!!emailError}
                helperText={emailError}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Password"
                type="password"
                value={password}
                onChange={handlePasswordChange}
                required
                fullWidth
                error={!!passwordError}
                helperText={passwordError}
              />
              <Typography variant="body2" className={`password-strength ${passwordStrength.toLowerCase()}`}>
                {passwordStrength}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                disablePortal
                freeSolo
                id="combo-box-type"
                options={animalTypes}
                fullWidth
                onInputChange={(_, newInputValue) => {
                  setPetType(newInputValue);
                }}
                renderInput={(params) => (
                  <TextField {...params} label={'Type'} required />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                label="Pet Name"
                value={petName}
                onChange={(e) => setPetName(e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6} style={{
              marginLeft: 'auto',
              marginRight: 'auto',
            }}>
              <TextField
                label="Phone Number"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                fullWidth
                error={!!phoneError}
                helperText={phoneError}
              />
            </Grid>
          </Grid>
          <button type="submit" disabled={!isFormValid}>Create Account</button>
        </form>
        <div className="back-to-login">
          <Link to="/" className="back-link">
            <span className="arrow">&#8592;</span> Back to Login
          </Link>
        </div>
      </div>
      <Banner
        open={snackbarOpen}
        handleClose={handleSnackbarClose}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
      <Spinner open={isLoading} />
    </div>
  );
};

export default CreateAccount;
