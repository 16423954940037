import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './redux/store';
import Spinner from './components/Utilities/Spinner'; 

const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(
  <Provider store={store}>
    <PersistGate loading={<Spinner open={true} />} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>
);
