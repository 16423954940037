import React, { useEffect, useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import axios from "axios";
import logo from "../components/assets/logo-pet.png";
import "../css/ViewMyPet.css";
import { GoogleMapsViewer } from "../components/GoogleMaps";
import { StyledAvatar } from "../components/AvatarUpload";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Spinner from "../components/Utilities/Spinner";
import NotFound from "../Pages/NotFound";
import LoginIcon from "@mui/icons-material/Login";
import { Banner } from "../components/Utilities/Snackbar";

function ViewMyPet() {
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const [petData, setPetData] = useState(null);
  const [avatar, setAvatar] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [QRCodeInvalid, setQRCodeInvalid] = useState(false);
  const [QRCodeAvailable, setQRCodeAvailable] = useState(false);
  const [QRCodeValidWithData, setQRCodeValidWithData] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("error");

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const navigate = useNavigate();

  const handleCreateAccountClick = () => {
    navigate(`/create-account?id=${id}`);
  };

  const linkNewPetClick = () => {
    navigate(`/link-tag?id=${id}`);
  };

  const handleLogin = () => {
    navigate("/");
  };

  useEffect(() => {
    if (!id) return;
    (async () => {
      setIsLoading(true);
      try {
        let response = await axios.get(`${global.config.serverUrl}/getPet/`, {
          headers: {
            id: id,
          },
        });
  
        let allData = response.data;
  
        if (response.status === 201) {
          setQRCodeInvalid(true);
          setQRCodeValidWithData(false);
        } else if (response.status === 202) {
          setQRCodeAvailable(true);
          setQRCodeValidWithData(false);
        } else {
          setQRCodeValidWithData(true);
        }
  
        if (response.status !== 201 && response.status !== 202) {
          response = await axios.get(`${global.config.serverUrl}/getOwner/`, {
            headers: {
              email: allData.ownerEmail,
            },
          });
  
          allData["email"] = allData["ownerEmail"];
          delete allData["ownerEmail"];
          allData = { ...allData, ...response.data };
          setPetData(allData);

          response = await axios.get("https://api.ipify.org?format=json");
          const ipAddress = response.data.ip;
  
          response = await axios.get(
            `https://freeipapi.com/api/json/${ipAddress}`
          );
          const locationData = response.data;
  
          try {
            await axios.post(
              `${global.config.serverUrl}/notifyScan/`,
              {
                city: locationData.cityName,
                country: locationData.countryName,
                timeZone: locationData.timeZones[0],
              },
              {
                headers: {
                  id: id,
                },
              }
            );
          } catch (error) {
            console.error("Error notifying scan:", error);
          }
  
          try {
            let res = await axios.get(`${global.config.serverUrl}/getPic/`, {
              headers: {
                id: id,
              },
              responseType: "arraybuffer",
            });
            let blob = new Blob([res.data], { type: res.headers["Content-Type"] });
            let imageUrl = URL.createObjectURL(blob);
            setAvatar(imageUrl);
          } catch (error) {
            console.error("Error fetching picture:", error);
          }
        }
      } catch (error) {
        setSnackbarMessage("An error has occurred while fetching data.");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [id]);
  


  if (!id) {
    return <NotFound />;
  }

  return (
    <div className="view-my-pet-container">
      {isLoading && <Spinner open={isLoading} />}

      {QRCodeInvalid && (
        <>
          <img
            style={{
              width: "320px",
              height: "420px",
              marginTop: "-100px",
              marginBottom: "-150px",
            }}
            src={logo}
            alt="Logo"
          />
          <Typography variant="h6" color="textPrimary" align="center" mt={5}>
            The QR Code you entered is Invalid.
          </Typography>
          <p className="signup-link">
            Don't have an account? <a href="/order">Order your tag now!</a>
          </p>
        </>
      )}

      {QRCodeAvailable && (
        <Box sx={{ textAlign: "center", mt: 5 }}>
          <img
            style={{
              width: "320px",
              height: "420px",
              marginTop: "-150px",
              marginBottom: "-150px",
            }}
            src={logo}
            alt="Logo"
          />
          <Typography variant="h6" color="textPrimary" mb={3}>
            Let's get started by creating your account.
          </Typography>
          <Button
            sx={{
              color: "black",
              backgroundColor: "#B4D479",
              fontSize: "0.9em",
              fontWeight: "bold",
              mt: -2,
            }}
            variant="contained"
            onClick={handleCreateAccountClick}
          >
            Create New Account
          </Button>

          <Typography
            variant="h3"
            color="#353634"
            mt={3}
            sx={{
              fontWeight: "semi-bold",
              textAlign: "center",
              fontSize: "1rem",
              padding: "8px",
            }}
          >
            Already have an account? Manage all your pets from a single account.
          </Typography>

          <Button
            sx={{
              color: "white",
              backgroundColor: "#476569",
              fontSize: "0.9em",
              fontWeight: "bold",
              mt: 2,
            }}
            variant="contained"
            onClick={linkNewPetClick}
          >
            Link tag to Account
          </Button>
        </Box>
      )}

      {QRCodeValidWithData && petData && (
        <>
          <div className="info-icon-container">
            <LoginIcon
              style={{
                position: "absolute",
                top: "10px",
                right: "10px",
                fontSize: "2rem",
                color: "#353634",
                cursor: "pointer",
              }}
              onClick={handleLogin}
            />
          </div>

          {avatar ? (
            <StyledAvatar
              src={avatar}
              alt="Avatar"
              style={{ marginBottom: "20px" }}
            />
          ) : (
            <img
              style={{
                width: "320px",
                height: "420px",
                marginTop: "-100px",
                marginBottom: "-170px",
              }}
              src={logo}
              alt="Logo"
            />
          )}

          <h2>{petData.petName || "Pet"}'s information</h2>
          <div className="pet-info">
            <section className="owner-info">
              <h2>Owner Information</h2>
              <p>
                <strong>Name:</strong> {petData.firstName} {petData.lastName}
              </p>
              <p>
                <strong>Phone Number:</strong> {petData.phoneNumber || "-"}
              </p>
              <p>
                <strong>Email:</strong> {petData.email}
              </p>
            </section>
            <section className="extras">
              <h2>Pet Information</h2>
              <p>
                <strong>Type:</strong> {petData.petType || "-"}
              </p>
              <p>
                <strong>Name:</strong> {petData.petName || "-"}
              </p>
              <p>
                <strong>Breed:</strong> {petData.petBreed || "-"}
              </p>
            </section>
            <section className="extras">
              <h2>Address</h2>
              <p>
                <strong>City:</strong> {petData.cityAddress || "-"}
              </p>
              <p>
                <strong>Street:</strong> {petData.streetAddress || "-"}
              </p>
              <p>
                <strong>Building:</strong> {petData.buildingAddress || "-"}
              </p>
            </section>
            <section className="extras">
              <h2>Extras</h2>
              <p>
                <strong>{petData.firstName}'s home Location:</strong>
              </p>
              {petData.lat && petData.lng ? (
                <GoogleMapsViewer
                  coordinates={{ lat: petData.lat, lng: petData.lng }}
                />
              ) : (
                <p>Google Maps coordinates not found for this user.</p>
              )}
            </section>
          </div>
        </>
      )}

      {!isLoading && !QRCodeInvalid && !QRCodeAvailable && petData && (
        <footer
          style={{
            width: "300px",
            marginTop: "50px",
            padding: "10px 0",
            backgroundColor: "#353634",
            color: "#b9f07f",
            textAlign: "center",
          }}
        >
          <Typography variant="body2">
            &copy; {new Date().getFullYear()} - That's my pet. All rights
            reserved.
          </Typography>
        </footer>
      )}
      <Banner
        open={snackbarOpen}
        handleClose={handleSnackbarClose}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </div>
  );
}

export default ViewMyPet;
