import React from 'react';
import { Link } from 'react-router-dom';
import '../css/NotFound.css';
import logo from '../components/assets/logo-pet.png';

function NotFound() {
  return (
    <div className="not-found-container">
      <img style={{ width: '320px', height:'420px', marginTop: '-130px', marginBottom: '-150px' }} src={logo} alt='Logo' />
      <h2>Oops! The requested page is unavailable</h2>
      <Link to="/" className="login-link">Click here to login</Link>
    </div>
  );
}

export default NotFound;
