import React, { useState } from 'react';
import logo from '../components/assets/logo-pet.png';
import Spinner from '../components/Utilities/Spinner';
import '../config';
import { Banner } from '../components/Utilities/Snackbar';
import { Typography } from '@mui/material';
import { Link } from 'react-router-dom';

const Verify = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  const handleResendEmailClick = () => {
    setSnackbarMessage('Please check your email for instructions.');
    setSnackbarSeverity('success');
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  return (
    <div className="login-container">
      <div className="login-form">
        <h1 className='login-title'>Activate Your Tag</h1>
        <Link to="/" style={{ textDecoration: 'none' }}>
          <img style={{ width: '320px', height: '420px', marginTop: '-110px', marginBottom: '-150px' }} src={logo} alt='Logo' />
        </Link>
        <form>
          <Typography variant="body2" color="textSecondary" sx={{ marginBottom: '8px' }}>
            We’ve sent you a verification email. Please activate your tag by clicking the link in the email.
          </Typography>
          <button style={{fontSize: '15px'}} type="button" onClick={handleResendEmailClick}>
          Didn't receive the email? Resend it now
          </button>
        </form>
      </div>
      <Spinner open={isLoading} />
      <Banner
        open={snackbarOpen}
        handleClose={handleSnackbarClose}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </div>
  );
};

export default Verify;
