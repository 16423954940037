import React from "react";
import Popup from "reactjs-popup";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { getFirstLetter } from "./Utils";
import Divider from "@mui/material/Divider";
import red from "@mui/material/colors/red";

export function PopupBar({ buttons, firstName, lastName }) {
  return (
    <Popup
      position="right center"
      trigger={
        <button
          style={{
            width: "50px",
            height: "50px",
            borderRadius: "53%",
            marginBottom: "40px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "white",
            border: "none",
            cursor: "pointer",
            outline: "none",
          }}
        >
          <Stack direction="row" spacing={2}>
            <Avatar sx={{ bgcolor: "#476569" }} alt="Initials">
              {getFirstLetter(firstName)}
              {getFirstLetter(lastName)}
            </Avatar>
          </Stack>
        </button>
      }
    >
      <div style={{ padding: "10px" }}>
        {buttons[0].map((button) => (
          <Button
            startIcon={button.icon}
            key={button.value}
            onClick={button.onClick}
            variant="contained"
            sx={{
              backgroundColor: button.color ? button.color : "#476569",
              fontSize: "10px", 
              padding: "3px 6px", 
              margin: "2px",
              minWidth: "60px", 
              minHeight: "25px", 
            }}
          >
            {button.value}
          </Button>
        ))}

     
        <Divider sx={{ margin: "10px 0" }} />

     
        {buttons[1].map((button) => (
          <Button
            startIcon={button.icon}
            key={button.value}
            onClick={button.onClick}
            variant="contained"
            sx={{
              backgroundColor: button.color ? button.color : red[800], 
              fontSize: "10px", 
              padding: "3px 6px", 
              margin: "2px", 
              minWidth: "60px",
              minHeight: "25px", 
            }}
          >
            {button.value}
          </Button>
        ))}
      </div>
    </Popup>
  );
}
