import axios from 'axios';

export async function isAuthenticated(email) {
    try {
        if (email === null || email === "")
            return false;
        await axios.get(
            `${global.config.serverUrl}/isvalidtoken/`,
            {
                headers: {
                    'token': localStorage.getItem('loginToken'),
                    'email': email
                },
            }
        );
        return true;
    } catch (e) {
        return false;
    }
}