import React, { useState } from "react";
import {
  Box,
  Container,
  Grid,
  TextField,
  Typography,
  Paper,
  Button,
  IconButton,
  OutlinedInput,
  InputAdornment,
  useMediaQuery,
  Alert,
} from "@mui/material";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { AddCircleOutline, RemoveCircleOutline } from "@mui/icons-material";
import item from "../components/assets/item.png";
import item2 from "../components/assets/item2.png";
import { Banner } from "../components/Utilities/Snackbar";
import logo from "../components/assets/logo-pet.png";
import Spinner from "../components/Utilities/Spinner";
import { Link } from "react-router-dom";

const Order = () => {
  const navigate = useNavigate();
  const [quantity, setQuantity] = useState(1);
  const [formValid, setFormValid] = useState(false);
  const [emailValid, setEmailValid] = useState(true);
  const isMobile = useMediaQuery("(max-width:600px)");

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  const [isLoading, setIsLoading] = useState(false);

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleIncrease = () => {
    setQuantity((prevQuantity) => prevQuantity + 1);
  };

  const handleDecrease = () => {
    if (quantity > 1) {
      setQuantity((prevQuantity) => prevQuantity - 1);
    }
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleInputChange = () => {
    const firstName = document.getElementById("firstName").value.trim();
    const lastName = document.getElementById("lastName").value.trim();
    const phoneNumber = document.getElementById("phoneNumber").value.trim();
    const email = document.getElementById("email").value.trim();
    const address = document.getElementById("address").value.trim();

    const emailIsValid = isValidEmail(email);
    setEmailValid(emailIsValid);

    setFormValid(
      firstName && lastName && phoneNumber && emailIsValid && address
    );
  };

  const placeOrder = async () => {
    const firstName = document.getElementById("firstName").value.trim();
    const lastName = document.getElementById("lastName").value.trim();
    const phoneNumber = document.getElementById("phoneNumber").value.trim();
    const email = document.getElementById("email").value.trim();
    const address = document.getElementById("address").value.trim();

    setIsLoading(true);
    try {
      await axios.post(`${global.config.serverUrl}/order/`, {
        email: email,
        name: `${firstName} ${lastName}`,
        phoneNumber: phoneNumber,
        address: address,
        orderQuantity: quantity,
      });
      setIsLoading(false);
      setSnackbarOpen(true);
      setSnackbarSeverity("success");
      setSnackbarMessage(
        "Order successfully set! Please check your email for instructions."
      );

      setTimeout(() => {
        navigate(`/about-us`);
      }, 3000);
    } catch (error) {
      setSnackbarMessage("Error placing the order. Please try again.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      setIsLoading(false);
    }
  };

  return (
    <Container
      maxWidth={isMobile ? "md" : false}
      style={{
        backgroundColor: "#f5f5dc",
        padding: "20px",
        minHeight: "100vh",
        width: isMobile ? "auto" : "100%",
        maxWidth: isMobile ? "md" : "100%",
        margin: 0,
      }}
    >
      <Paper style={{ padding: "20px", backgroundColor: "#fff" }}>
        <Typography
          variant="h5"
          style={{
            fontFamily: "Arial, sans-serif",
            fontWeight: "bold",
            textAlign: "center",
          }}
        >
          Order Your Tag
        </Typography>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          mb={2}
          sx={{ mt: -15 }}
        >
          <Link to="/" style={{ textDecoration: "none" }}>
            <img
              src={logo}
              alt="Logo"
              style={{ width: "320px", height: "420px", cursor: "pointer" }}
            />
          </Link>
        </Box>
        <Grid container spacing={3} sx={{ mt: -23 }}>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="First Name"
              variant="outlined"
              id="firstName"
              onChange={handleInputChange}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Last Name"
              variant="outlined"
              id="lastName"
              onChange={handleInputChange}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Phone Number"
              variant="outlined"
              id="phoneNumber"
              onChange={handleInputChange}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Email"
              variant="outlined"
              id="email"
              onChange={handleInputChange}
              required
              error={!emailValid}
              helperText={!emailValid ? "Please enter a valid email." : ""}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Address"
              variant="outlined"
              id="address"
              onChange={handleInputChange}
              required
            />
          </Grid>
          <Grid item xs={12} display="flex" alignItems="center">
            <Box display="flex" alignItems="center">
              <img
                src={item}
                alt="Product"
                style={{ width: "60px", height: "75px", borderRadius: "5px" }}
              />
              <img
                src={item2}
                alt="Product"
                style={{
                  width: "60px",
                  height: "75px",
                  borderRadius: "5px",
                  marginLeft: "10px",
                }}
              />
            </Box>
            <OutlinedInput
              type="number"
              value={quantity}
              onChange={(e) =>
                setQuantity(Math.max(1, parseInt(e.target.value, 10)))
              }
              startAdornment={
                <InputAdornment position="start">
                  <IconButton onClick={handleDecrease}>
                    <RemoveCircleOutline />
                  </IconButton>
                </InputAdornment>
              }
              endAdornment={
                <InputAdornment position="end">
                  <IconButton onClick={handleIncrease}>
                    <AddCircleOutline />
                  </IconButton>
                </InputAdornment>
              }
              inputProps={{ min: 1, style: { textAlign: "center" } }}
              style={{
                maxWidth: "170px",
                borderColor: "#f5f5dc",
                marginLeft: "16px",
                marginTop: "10px",
              }}
            />
          </Grid>
          <Grid item xs={12} textAlign={isMobile ? "center" : "left"} mt={2}>
            <Typography variant="body1">
              Price: ${(quantity * 25.0).toFixed(2)}
            </Typography>
          </Grid>
          <Grid item xs={12} textAlign="center">
            <Button
              variant="contained"
              style={{
                backgroundColor: formValid ? "#B4D479" : "#808080",
                color: formValid ? "#353634" : "#f5f5dc",
                fontWeight: "bold",
              }}
              onClick={placeOrder}
              disabled={!formValid}
            >
              Place Order
            </Button>
          </Grid>
        </Grid>
        <Alert
          severity="info"
          sx={{
            backgroundColor: "#B4D479",
            mt: 2,
            color: "#353634",
            fontSize: "0.9rem",
            padding: "14px",
            borderRadius: "8px",
            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
          }}
        >
          For $25, you'll receive the tag, including the subscription and
          delivery fees all over Lebanon.
        </Alert>
      </Paper>
      <Banner
        open={snackbarOpen}
        message={snackbarMessage}
        severity={snackbarSeverity}
        onClose={handleSnackbarClose}
      />
      <Spinner open={isLoading} />
      <footer
        style={{
          margin: "10px auto",
          marginTop: "20px",
          padding: "10px 0",
          backgroundColor: "#353634",
          color: "#b9f07f",
          textAlign: "center",
          width: "300px",
        }}
      >
        <Typography variant="body2">
          &copy; {new Date().getFullYear()} - That's my pet. All rights
          reserved.
        </Typography>
      </footer>
    </Container>
  );
};

export default Order;
