import { createSlice } from '@reduxjs/toolkit';

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    userInfo: {
      email: '',
      firstName: '',
      lastName: '',
      qrCodeId: '',
      token: '',
    },
    isAuthenticated: false,
    isLinkingTag: false,
  },
  reducers: {
    setUser: (state, action) => {
      const { email, firstName, lastName, qrCodeId, token } = action.payload;
      state.userInfo = { email, firstName, lastName, qrCodeId, token };
      state.isAuthenticated = true;
    },
    setInitials: (state, action) => {
      const { firstName, lastName} = action.payload;
      state.userInfo = { firstName, lastName};
    },        
    setLinking: (state) => {
      state.isLinkingTag = true;
    },
    resetLinking: (state) => {
      state.isLinkingTag = false;
    },
    clearUser: (state) => {
      state.userInfo = {
        email: '',
        firstName: '',
        lastName: '',
        token: '',
      };
      state.isAuthenticated = false;
    },
  },
});

export const { setUser, clearUser, setInitials, setLinking, resetLinking } = userSlice.actions;

export default userSlice.reducer;
