import { createSlice } from '@reduxjs/toolkit';

export const petSlice = createSlice({
  name: 'pet',
  initialState: {
    petInfo: [], 
    isAuthenticated: false,
  },
  reducers: {
    setPet: (state, action) => {
      state.petInfo = action.payload;
      state.isAuthenticated = true;
    },
    clearPet: (state) => {
      state.petInfo = [];
      state.isAuthenticated = false;
    },
  },
});

export const { setPet, clearPet } = petSlice.actions;

export default petSlice.reducer;
