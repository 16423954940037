import React from "react";
import { Box, Container, Grid, Link, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import logo from "../components/assets/logo-pet.png";
import banner from "../components/assets/banner.png";
import VisionMissionValues from "../components/VisionMissionValues";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import InstagramIcon from "@mui/icons-material/Instagram";
import EmailIcon from "@mui/icons-material/MailOutline";

const AboutUs = () => {
  return (
    <Container
      maxWidth={false}
      style={{
        backgroundColor: "#f5f5dc",
        padding: "20px",
        color: "#353634",
        minHeight: "120vh",
      }}
    >
      <Box display="flex" flexDirection="column" alignItems="center" mb={4}>
        <RouterLink to="/" style={{ textDecoration: "none" }}>
          <img
            style={{
              width: "320px",
              height: "420px",
              marginTop: "-130px",
              marginBottom: "-150px",
            }}
            src={logo}
            alt="Logo"
          />
        </RouterLink>

        <Link
          href="/order"
          style={{
            fontFamily: "Arial, sans-serif",
            fontWeight: "bold",
            fontSize: "22px",
            color: "#B4D479",
            textDecoration: "none",
            backgroundColor: "#353634",
            padding: "10px 20px",
            borderRadius: "5px",
            boxShadow: "0 4px 8px rgba(0,0,0,0.2)",
            display: "inline-block",
            transition: "background-color 0.3s, transform 0.3s",
            whiteSpace: "nowrap",
          }}
          onMouseEnter={(e) => {
            e.target.style.backgroundColor = "#476569";
            e.target.style.color = "white";
            e.target.style.transform = "scale(1.05)";
          }}
        >
          <span style={{ fontSize: "24px", marginRight: "10px" }}>→</span>
          Order yours now
          <span style={{ fontSize: "24px", marginLeft: "10px" }}>←</span>
        </Link>

        <Typography
          variant="body1"
          style={{ marginTop: "15px", fontSize: "14.5px", color: "#353634" }}
        >
          Already have an account?{" "}
          <RouterLink
            to="/"
            style={{ color: "#B4D479", fontWeight: "bold", textDecoration: 'none' }}
          >
            Log in
          </RouterLink>
        </Typography>

        <Box display="flex" justifyContent="center" mt={2}>
          <Link
            href="https://wa.me/+96176656993"
            target="_blank"
            sx={{ color: "black", marginRight: "10px" }}
          >
            <WhatsAppIcon />
          </Link>
          <Link
            href="https://www.instagram.com/thatsmypet.lb?igsh=emI0Z3NoY2hlM3F2&utm_source=qr"
            target="_blank"
            sx={{ color: "black" }}
          >
            <InstagramIcon />
          </Link>
          <Link
            href="mailto:sales@thatsmypet.org"
            target="_blank"
            sx={{ color: "black", ml: 1 }}
          >
            <EmailIcon />
          </Link>
        </Box>
      </Box>

      <Box
        display="flex"
        justifyContent="center"
        style={{ marginTop: "-15px" }}
      >
        <img
          style={{
            maxWidth: "100%",
            height: "auto",
            marginLeft: "auto",
            marginRight: "auto",
          }}
          src={banner}
          alt="Banner"
        />
      </Box>

      <Grid container spacing={4} mt={0}>
        <Grid item xs={12}>
          <VisionMissionValues
            title="What We Offer"
            content="High-quality pet tags that display a digital, read-only profile when scanned. Easily manage and update your pet’s information through your account, and receive precise time and location notifications if your lost pet is scanned."
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <VisionMissionValues
            title="Our Mission"
            content="To give pet owners peace of mind by offering a reliable system that helps reunite lost pets with their families through our cutting-edge tagging technology."
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <VisionMissionValues
            title="Our Vision"
            content="To empower pets with a digital identity and become a trusted platform for pet owners, helping them quickly and efficiently find their lost companions."
          />
        </Grid>
      </Grid>

      <footer
        style={{
          margin: "10px auto",
          marginTop: "20px",
          padding: "10px 0",
          backgroundColor: "#353634",
          color: "#b9f07f",
          textAlign: "center",
          width: "300px",
        }}
      >
        <Typography variant="body2">
          &copy; {new Date().getFullYear()} - That's my pet. All rights
          reserved.
        </Typography>
      </footer>
    </Container>
  );
};

export default AboutUs;
